import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from '../../store/store'
import { URLFragmentParams } from '../../utils/url'
import { GUILDS_ROUTE, HOME_ROUTE } from '../routes'

const ConsumeDiscordCallback: React.FC = () => {
  const navigate = useNavigate()
  const {
    appUser: { setUserToken, isAuthenticated, whoAmI },
  } = useStore()

  useEffect(() => {
    if (isAuthenticated) {
      navigate(HOME_ROUTE)
      return
    }

    if (window.location.hash === '') {
      // don't do anything if the hash is empty
      return
    }

    // look at the url and get our auth token
    // we'll need to make an api call to swap it for a token
    const params = new URLFragmentParams(window.location.hash)
    try {
      const token = params.get('access_token')
      const expiresIn = params.get('expires_in')
      setUserToken(token, Number(expiresIn))
      whoAmI()
    } catch (err) {
      console.error('err=', err)
      navigate(HOME_ROUTE)
      return
    }

    navigate(GUILDS_ROUTE)
  }, [isAuthenticated])

  return <span>consuming discord callback</span>
}

export { ConsumeDiscordCallback }
