import React from 'react'
import { Box as _Box, Grid, SxProps, Theme } from '@mui/material'

interface BoxProps {
  children?: JSX.Element | JSX.Element[]
  renderAvatarCutout?: boolean
  style?: SxProps<Theme>
}

const Box: React.FC<BoxProps> = ({
  children,
  renderAvatarCutout = false,
  style = {},
}) => (
  <_Box
    sx={{
      backgroundColor: 'background.paper',
      borderTopRightRadius: '15px',
      borderBottomRightRadius: '15px',
      ...style,
    }}
  >
    <Grid
      container
      spacing={2}
      alignItems="center"
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginRight: '1rem',
      }}
    >
      {renderAvatarCutout && (
        <_Box
          sx={{
            display: 'block',
            width: '60px',
            height: '98px',
            marginLeft: '16px',
            overflow: 'hidden',
            position: 'absolute',
            transform: 'scaleX(-1)',
            zIndex: '0',
            ':after': {
              content: '""',
              // width: '48px',
              // height: '81px',
              MozBorderRadius: '48px',
              WebkitBorderRadius: '48px',
              borderRadius: '48px',
              background: 'rgba(0, 0, 0, 0)',
              position: 'absolute',
              border: '49px solid #23272A',
            },
          }}
        />
      )}
      {children}
    </Grid>
  </_Box>
)

export { Box }
