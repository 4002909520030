import { getParent, types } from 'mobx-state-tree'
import { UserResourceManagerInstance } from '../resource-managers/user'
import { StoreInstance } from '../store'
import { EventUserModel, EventUserModelInstance } from './event-user'

enum EventStatus {
  SCHEDULED = 1,
  ACTIVE = 2,
  COMPLETED = 3,
  CANCELED = 4,
}

const EventModel = types
  .model('EventModel', {
    id: types.identifier,
    channelId: types.maybeNull(types.string),
    creatorId: types.maybe(types.string),
    name: types.string,
    description: types.maybeNull(types.string),
    scheduledStartTime: types.string,
    scheduledEndTime: types.maybeNull(types.string),
    status: types.number,
    userCount: types.maybe(types.number),
    users: types.array(types.reference(EventUserModel)),
    imageHash: types.maybeNull(types.string),
  })
  .views((self) => ({
    get imageURI(): string {
      if (self.imageHash === null) {
        return ''
      }

      return `https://cdn.discordapp.com/guild-events/${self.id}/${self.imageHash}.png`
    },
    get scheduledStartTimeAsDate(): Date {
      return new Date(self.scheduledStartTime)
    },
    get statusAsString(): string {
      return EventStatus[self.status]
    },
    get creator(): EventUserModelInstance | undefined {
      return self.users.find(({ id }) => id === self.creatorId)
    },
    get amIAlreadyInterested(): boolean {
      // get our user id from the user resource manager
      // where our token lives
      const parent = getParent(self, 4) as unknown as StoreInstance
      const { id } = parent.appUser as UserResourceManagerInstance
      if (id === undefined) {
        throw new Error('user id undefined.')
      }

      return self.users.findIndex((user) => user.id === id) > -1
    },
    get areEventUsersAlreadyLoaded(): boolean {
      return self.userCount === self.users.length
    },
  }))
  .actions((self) => ({
    addEventUsers(userIds: string[]) {
      const alreadyExistingUserIds = self.users.map(({ id }) => id)
      const usersToAdd = userIds.filter(
        (userId) => alreadyExistingUserIds.indexOf(userId) === -1
      )

      self.users.push(...usersToAdd)
    },
  }))

export { EventModel }
