import createTheme from '@mui/material/styles/createTheme'

const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  typography: {
    fontFamily: [
      'Lato',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
      '-apple-system',
      'BlinkMacSystemFont',
    ].join(','),
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#5865F2',
    },
    secondary: {
      main: '#99AAB5',
    },
    error: {
      main: '#ED4245',
    },
    warning: {
      main: '#FEE75C',
    },
    info: {
      main: '#FFFFFF',
    },
    success: {
      main: '#57F287',
    },
    background: {
      default: '#23272A',
      paper: '#2C2F33',
    },
  },
})

export { theme }
