import CheckIcon from '@mui/icons-material/Check'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import TodayIcon from '@mui/icons-material/Today'
import {
  Avatar,
  Box as _Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { format, isThisYear } from 'date-fns'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '../../components/box'
import { useStore } from '../../store/store'
import { LoadState } from '../../types'
import { GUILDS_ROUTE, HOME_ROUTE } from '../routes'

const Events: React.FC = observer(() => {
  const navigate = useNavigate()
  const {
    appUser: { isAuthenticated },
    selectedGuild,
  } = useStore()
  const loadEventUsers = selectedGuild?.loadEventUsers

  const [eventUsersDialogOpen, setEventUsersDialogOpen] = useState(false)
  const [notifyEventDialogOpen, setNotifyEventDialogOpen] = useState(false)
  const [focusedEventId, setFocusedEventId] = useState('')

  const focusedEvent = useMemo(
    () => selectedGuild?.events.find(({ id }) => id === focusedEventId),
    [focusedEventId]
  )

  const toggleEventUsersDialog = () => {
    setEventUsersDialogOpen(!eventUsersDialogOpen)
  }

  const toggleNotifyEventDialog = () => {
    setNotifyEventDialogOpen(!notifyEventDialogOpen)
  }

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(HOME_ROUTE)
      return
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (selectedGuild === undefined) {
      // go back to guild select
      navigate(GUILDS_ROUTE)
    }
  }, [selectedGuild])

  useEffect(() => {
    if (selectedGuild?.events.length === 0) {
      return
    }

    selectedGuild?.events.reduce(async (promise, { id }) => {
      await promise // wait for the last request to finish
      await new Promise((resolve) => setTimeout(resolve, 2_500)) // wait another second after for rate limits
      loadEventUsers && (await loadEventUsers(id))
    }, Promise.resolve())
  }, [selectedGuild?.events.length])

  // sort event list by scheduled start time
  const sortedSelectedGuildEvents = [...(selectedGuild?.events ?? [])]
  sortedSelectedGuildEvents.sort(
    (a, b) =>
      a.scheduledStartTimeAsDate.getTime() -
      b.scheduledStartTimeAsDate.getTime()
  )

  const showUsersPopupForEvent = (eventId: string) => () => {
    const event = selectedGuild?.events.find(({ id }) => id === eventId)
    if (event === undefined) {
      return
    }

    if (!event.areEventUsersAlreadyLoaded) {
      return
    }

    setFocusedEventId(eventId)
    toggleEventUsersDialog()
  }

  const showNotifyPopupForEvent = (eventId: string) => () => {
    setFocusedEventId(eventId)
    toggleNotifyEventDialog()
  }

  // render events to show to user
  const eventsToRender = sortedSelectedGuildEvents.map((event) => {
    const areUsersLoaded =
      selectedGuild?.eventUsersLoadStateVolatile[event.id] === LoadState.done

    const goingNotGoingBtn = (interested: boolean) => (
      <Button
        color={areUsersLoaded ? 'primary' : 'error'}
        variant="contained"
        sx={{ cursor: 'default' }}
      >
        {interested && <CheckIcon />} Interested
      </Button>
    )

    const content = (
      <>
        <Typography variant="h5" component="div">
          {event.name}
        </Typography>
        <Typography sx={{ margin: '1rem 0' }} color="text.secondary">
          {event.description}
        </Typography>
      </>
    )

    return (
      <Grid key={event.id} xs="auto" sx={{ marginLeft: '1rem' }}>
        <Card>
          <CardContent>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <TodayIcon color="primary" />
                  </Grid>
                  <Grid item sx={{ paddingLeft: '1rem' }}>
                    <Typography
                      sx={{ fontSize: 16, fontWeight: 700 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      {format(
                        new Date(event.scheduledStartTime),
                        isThisYear(new Date(event.scheduledStartTime))
                          ? 'E LLL do · h:mm a'
                          : 'E LLL do, y · h:mm a'
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <Tooltip
                      enterTouchDelay={0}
                      placement="top"
                      title={`Created by ${
                        event.creator?.nickname ??
                        event.creator?.username ??
                        'unknown user'
                      }`}
                    >
                      <Avatar
                        alt={`${
                          event.creator?.nickname ??
                          event.creator?.username ??
                          'unknown user'
                        } user icon`}
                        src={event.creator?.avatarURI}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Chip
                      label={
                        <_Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                          }}
                        >
                          <PeopleAltIcon fontSize="small" />
                          &nbsp;&nbsp;
                          <Typography>{event.userCount}</Typography>
                        </_Box>
                      }
                      clickable={event.areEventUsersAlreadyLoaded}
                      onClick={showUsersPopupForEvent(event.id)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {event.imageHash !== null ? (
              <Grid container alignItems="center" sx={{ marginTop: '0.5rem' }}>
                <Grid item xs={8}>
                  {content}
                </Grid>
                <Grid item xs={4}>
                  <img
                    src={event.imageURI}
                    style={{
                      width: '100%',
                      height: 'auto',
                      borderRadius: '15px',
                    }}
                  />
                </Grid>
              </Grid>
            ) : (
              <_Box sx={{ marginTop: '0.5rem' }}>{content}</_Box>
            )}

            <hr />

            <Grid
              container
              justifyContent="end"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={showNotifyPopupForEvent(event.id)}
                >
                  <NotificationsActiveIcon />
                </Button>
              </Grid>
              <Grid item>
                {event.amIAlreadyInterested ? (
                  goingNotGoingBtn(true)
                ) : (
                  <Tooltip
                    enterTouchDelay={0}
                    title={
                      areUsersLoaded
                        ? 'If you are interested, please do so via discord'
                        : 'Could not fetch users attending this event'
                    }
                  >
                    {goingNotGoingBtn(false)}
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    )
  })

  // render selected guild at top of page for context to user
  const selectedGuildToRender = (
    <Box key={selectedGuild?.id} renderAvatarCutout>
      <Grid>
        <_Box
          sx={{
            display: 'block',
            position: 'absolute',
            width: '96px',
            height: '96px',
            backgroundColor: '#383838',
            borderRadius: '48px',
          }}
        />
        <Avatar
          alt={`${selectedGuild?.name} guild icon`}
          src={selectedGuild?.iconURI}
          sx={{
            width: 96,
            height: 96,
          }}
        >
          {selectedGuild?.name.charAt(0)}
        </Avatar>
      </Grid>
      <Grid
        xs="auto"
        sx={{
          marginLeft: '1rem',
        }}
      >
        <h3 title={selectedGuild?.name}>
          {(selectedGuild?.name.length ?? 0) > 28
            ? selectedGuild?.name.substring(0, 28).padEnd(31, '.')
            : selectedGuild?.name}
        </h3>
      </Grid>
    </Box>
  )

  const focusedEventUsersToRender = useMemo(() => {
    const innerUsers = focusedEvent?.users.map(
      ({
        id,
        avatarURI,
        nickname,
        username,
        memberAvatarHash,
        getCustomGuildAvatarURI,
      }) => (
        <Grid key={id} item>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Avatar
                alt={`${nickname ?? username} user icon`}
                src={
                  memberAvatarHash
                    ? getCustomGuildAvatarURI(
                        selectedGuild?.id ?? '<no_guild_id_found>'
                      )
                    : avatarURI
                }
              />
            </Grid>
            <Grid item>
              <Typography>{nickname ?? username}</Typography>
            </Grid>
          </Grid>
        </Grid>
      )
    )

    return (
      <Stack direction="column" spacing={2}>
        {innerUsers}
      </Stack>
    )
  }, [focusedEvent])

  return (
    <div className="area" style={{ width: '60vh' }}>
      {/* dialog for showing an event's interested user list */}
      <Dialog open={eventUsersDialogOpen} onClose={toggleEventUsersDialog}>
        <DialogTitle>
          <Typography sx={{ fontWeight: 700 }}>
            {focusedEvent?.userCount} Interested
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{focusedEventUsersToRender}</DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog open={notifyEventDialogOpen} onClose={toggleNotifyEventDialog}>
        <DialogTitle>
          <Typography sx={{ fontWeight: 700 }}>Setup notifications</Typography>
        </DialogTitle>
        <List sx={{ pt: 0 }}>
          <ListItemButton>
            <ListItemText>Add to Google Calendar</ListItemText>
          </ListItemButton>
        </List>
      </Dialog>

      <Stack direction="column" spacing={6}>
        {selectedGuildToRender}
        <hr />
        {eventsToRender}
      </Stack>
    </div>
  )
})

export { Events }
