import { Avatar, Box, Grid, Stack } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from '../../store/store'
import { Box as _Box } from '../../components/box'
import { EVENTS_ROUTE, HOME_ROUTE } from '../routes'

const Guild: React.FC = observer(() => {
  const navigate = useNavigate()
  const {
    appUser: { isAuthenticated },
    loadGuilds,
    guilds,
    loadEvents,
    selectGuildById,
  } = useStore()

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(HOME_ROUTE)
      return
    }

    loadGuilds()
  }, [isAuthenticated])

  useEffect(() => {
    if (guilds.length === 0) {
      return
    }

    loadEvents()
  }, [guilds.length])

  const selectGuild = (guildId: string) => () => {
    selectGuildById(guildId)

    // navigate to the events page
    navigate(EVENTS_ROUTE)
  }

  const guildsToRender = guilds
    .filter(({ hasEvents }) => hasEvents)
    .map(({ iconURI, name, id }) => (
      <div key={id} onClick={selectGuild(id)}>
        <_Box renderAvatarCutout style={{ cursor: 'pointer' }}>
          <Grid>
            <Box
              sx={{
                display: 'block',
                position: 'absolute',
                width: '96px',
                height: '96px',
                backgroundColor: 'background.paper',
                borderRadius: '48px',
              }}
            />
            <Avatar
              alt={`${name} guild icon`}
              src={iconURI}
              sx={{
                width: 96,
                height: 96,
              }}
            >
              {name.charAt(0)}
            </Avatar>
          </Grid>
          <Grid
            xs="auto"
            sx={{
              marginLeft: '1rem',
            }}
          >
            <h3 title={name}>
              {name.length > 28 ? name.substring(0, 28).padEnd(31, '.') : name}
            </h3>
          </Grid>
        </_Box>
      </div>
    ))

  return (
    <div className="area">
      <Stack direction="column" spacing={6}>
        {guildsToRender}
      </Stack>
    </div>
  )
})

export { Guild }
