interface GuildResponse {
  id: string
  icon: string
  name: string
}

type UserGuildsResponse = GuildResponse[]

interface GuildScheduledEventResponse {
  id: string
  channel_id: string | null
  creator_id?: string
  name: string
  description?: string
  scheduled_start_time: string
  scheduled_end_time: string | null
  status: number
  user_count?: number
  image?: string
}
type GuildScheduledEventsResponse = GuildScheduledEventResponse[]

interface NetlifyFunctionEventsResponse {
  error: boolean
  message: string | null
  data: GuildScheduledEventsResponse
}

enum LoadState {
  error = 'error',
  inProgress = 'inProgress',
  done = 'done',
  init = 'init',
}

interface UserResponse {
  id: string
  username: string
  discriminator: string
  avatar?: string
  bot?: boolean
  system?: boolean
  mfa_enabled?: boolean
  banner?: string
  accent_color?: number
  locale?: string
  verified?: boolean
  email?: string
  flags?: number
  premium_type?: number
  public_flags?: number
}

interface GuildMemberResponse {
  user?: UserResponse
  nick?: string
  avatar?: string
  roles: string[]
  joined_at: string
  premium_since?: string
  deaf: boolean
  mute: boolean
  pending?: boolean
  permissions?: string
  communication_disabled_until?: string
}

interface GuildScheduledEventUserResponse {
  guild_scheduled_event_id: string
  user: UserResponse
  member?: GuildMemberResponse
}
type GuildScheduledEventUsersResponse = GuildScheduledEventUserResponse[]

interface NetlifyFunctionEventUsersResponse {
  error: boolean
  message: string | null
  data: GuildScheduledEventUsersResponse
}

interface DiscordRateLimitResponse {
  global: boolean
  message: string
  retry_after: number
}

interface MeResponse {
  id: string
  username: string
  discriminator: string
  avatar: string
  mfa_enabled?: boolean
  banner?: string
  accent_color?: number
  locale?: string
  flags?: number
  premium_type?: number
  public_flags?: number
}

export { LoadState }

export type {
  GuildResponse,
  UserGuildsResponse,
  GuildScheduledEventResponse,
  GuildScheduledEventsResponse,
  NetlifyFunctionEventsResponse,
  UserResponse,
  GuildMemberResponse,
  GuildScheduledEventUserResponse,
  GuildScheduledEventUsersResponse,
  NetlifyFunctionEventUsersResponse,
  DiscordRateLimitResponse,
  MeResponse,
}
