import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { App } from './scenes/app'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { ConsumeDiscordCallback } from './scenes/discord'
import { Provider, store } from './store/store'
import { Guild } from './scenes/guilds'
import { Events } from './scenes/events'
import {
  DISCORD_LOGIN_CALLBACK_ROUTE,
  EVENTS_ROUTE,
  GUILDS_ROUTE,
  HOME_ROUTE,
} from './scenes/routes'
import { theme } from './theme'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider value={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path={HOME_ROUTE} element={<App />} />
            <Route
              path={DISCORD_LOGIN_CALLBACK_ROUTE}
              element={<ConsumeDiscordCallback />}
            />
            <Route path={GUILDS_ROUTE} element={<Guild />} />
            <Route path={EVENTS_ROUTE} element={<Events />} />
          </Routes>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
