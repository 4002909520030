import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { DiscordLogin } from '../../components/discord-login'
import { useStore } from '../../store/store'
import { GUILDS_ROUTE } from '../routes'

const App: React.FC = observer(() => {
  const navigate = useNavigate()
  const {
    appUser: { isAuthenticated },
  } = useStore()

  useEffect(() => {
    if (isAuthenticated) {
      navigate(GUILDS_ROUTE)
    }
  }, [])

  return (
    <>
      <DiscordLogin />
    </>
  )
})

export { App }
