import { getSnapshot, Instance, types } from 'mobx-state-tree'
import { createContext, useContext } from 'react'
import { GuildResourceManager } from './resource-managers/guilds'
import {
  localStorageKey as localStorageKeyURM,
  UserResourceManager,
} from './resource-managers/user'

const Store = types.compose(
  types.model({
    appUser: UserResourceManager,
  }),
  GuildResourceManager
)

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StoreInstance extends Instance<typeof Store> {}

// look at localstorage to try loading who we are
let userInitialState = { appUser: {} }
if (window.localStorage.getItem(localStorageKeyURM) !== null) {
  const state = JSON.parse(
    window.localStorage.getItem(localStorageKeyURM) as string
  )
  if (UserResourceManager.is(state)) {
    userInitialState = { appUser: state }
  }
}

const store = Store.create(Object.assign({ appUser: {} }, userInitialState))

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const globalAsAny = global as any
globalAsAny.store = store
globalAsAny.getSnapshot = getSnapshot

const StoreContext = createContext<null | StoreInstance>(store)
const Provider = StoreContext.Provider

function useStore(): StoreInstance {
  const store = useContext(StoreContext)
  if (store === null) {
    throw new Error(
      'Store cannot be null, please add a context provider for scenarioExplorerStore'
    )
  }
  return store
}

export { store, Provider, useStore, Store }
export type { StoreInstance }
