import React from 'react'

const DiscordLogin: React.FC = () => {
  const url = `https://discord.com/api/oauth2/authorize?client_id=1004275924558172171&redirect_uri=${encodeURIComponent(
    `${window.location.origin}/discord`
  )}&response_type=token&scope=guilds%20identify`

  function login() {
    window.location.href = url
  }

  return <button onClick={login}>login with discord</button>
}

export { DiscordLogin }
