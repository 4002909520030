import { flow, types } from 'mobx-state-tree'
import {
  LoadState,
  NetlifyFunctionEventsResponse,
  UserGuildsResponse,
} from '../../types'
import { GuildModel } from '../models/guild'
import { StoreInstance } from '../store'
import { UserResourceManagerInstance } from './user'

const GuildResourceManager = types
  .model('GuildResourceManager', {
    guilds: types.array(GuildModel),
    selectedGuildId: types.maybeNull(types.string),
  })
  .volatile(() => ({
    loadStateVolatile: LoadState.init,
    guildEventsLoadStateVolatile: LoadState.init,
  }))
  .views((self) => ({
    get selectedGuild() {
      return self.guilds.find(({ id }) => id === self.selectedGuildId)
    },
  }))
  .actions((self) => ({
    loadEvents: flow(function* () {
      if (
        [LoadState.init, LoadState.error].includes(
          self.guildEventsLoadStateVolatile
        )
      ) {
        self.guildEventsLoadStateVolatile = LoadState.inProgress

        try {
          yield self.guilds.reduce(async (promise, { id, addEvents }) => {
            await promise
            const endpoint = `/.netlify/functions/events?guildid=${id}`
            const resp = await fetch(endpoint)
            const json: NetlifyFunctionEventsResponse = await resp.json()
            if (json.data === undefined) {
              console.error('api error=', json)
              return
            }

            // load the events into the guild
            addEvents(...json.data)
          }, Promise.resolve())

          self.guildEventsLoadStateVolatile = LoadState.done
        } catch (err) {
          console.log('error=', err)
          self.guildEventsLoadStateVolatile = LoadState.error
        }
      }
    }),
    loadGuilds: flow(function* () {
      if ([LoadState.init, LoadState.error].includes(self.loadStateVolatile)) {
        self.loadStateVolatile = LoadState.inProgress

        // get our token first
        const parent = self as unknown as StoreInstance
        const { token } = parent.appUser as UserResourceManagerInstance
        if (token === undefined) {
          throw new Error('token undefined.')
        }

        // try loading the guilds with our token
        const host = 'https://discord.com/api'
        const endpoint = '/users/@me/guilds'

        try {
          const resp: Response = yield fetch(host + endpoint, {
            headers: {
              authorization: `Bearer ${token}`,
            },
          })
          const guilds: UserGuildsResponse = yield resp.json()
          guilds
            .filter(({ id }) => id === '249391493880479744')
            .forEach((guild) => {
              self.guilds.push(GuildModel.create({ ...guild, events: [] }))
            })

          self.loadStateVolatile = LoadState.done
        } catch (err) {
          console.error('err=', err)
          self.loadStateVolatile = LoadState.error
        }
      }
    }),
  }))
  .actions((self) => ({
    selectGuildById(id: string): void {
      if (
        !self.guilds.find(({ id: guildId }) => id === guildId) !== undefined
      ) {
        self.selectedGuildId = id
        return
      }

      throw new Error(
        'no guild exists in the model with the specified id: ' + id
      )
    },
  }))

export { GuildResourceManager }
