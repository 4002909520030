import { flow, Instance, onSnapshot, types } from 'mobx-state-tree'
import { addSeconds, isPast } from 'date-fns'
import { LoadState, MeResponse } from '../../types'

const localStorageKey = 'app.mst.urm'

const UserResourceManager = types
  .model('UserResourceManager', {
    id: types.maybe(types.string),
    token: types.maybe(types.string),
    tokenExpiresIn: types.maybe(types.number), // number of seconds
    tokenFetchedAt: types.maybe(types.string),
  })
  .volatile(() => ({
    loadStateVolatile: LoadState.init,
  }))
  .views((self) => ({
    get isAuthenticated(): boolean {
      const tokenExpiresAtDate = addSeconds(
        new Date(self.tokenFetchedAt ?? new Date().toISOString()),
        self.tokenExpiresIn ?? 0
      )

      return (
        self.token !== undefined &&
        self.token !== '' &&
        !isPast(tokenExpiresAtDate)
      )
    },
  }))
  .actions((self) => ({
    setUserToken: (token: string, expiresIn: number) => {
      self.token = token
      self.tokenExpiresIn = expiresIn
      self.tokenFetchedAt = new Date().toISOString()
    },
  }))
  .actions((self) => ({
    whoAmI: flow(function* () {
      if ([LoadState.init, LoadState.error].includes(self.loadStateVolatile)) {
        if (self.token === undefined) {
          throw new Error('token undefined.')
        }

        self.loadStateVolatile = LoadState.inProgress

        const host = 'https://discord.com/api'
        const endpoint = '/users/@me'

        try {
          const resp: Response = yield fetch(host + endpoint, {
            headers: {
              authorization: `Bearer ${self.token}`,
            },
          })
          const data: MeResponse = yield resp.json()

          self.id = data.id

          self.loadStateVolatile = LoadState.done
        } catch (err) {
          console.error('err=', err)
          self.loadStateVolatile = LoadState.error
        }
      }
    }),
  }))
  .actions((self) => ({
    // record changes to localstorage to be able to reload on reload...
    // without having to login again
    afterCreate() {
      onSnapshot(self, (snapshot) => {
        window.localStorage.setItem(localStorageKey, JSON.stringify(snapshot))
      })
    },
  }))

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UserResourceManagerInstance
  extends Instance<typeof UserResourceManager> {}

export { UserResourceManager, localStorageKey }
export type { UserResourceManagerInstance }
