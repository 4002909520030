import { flow, types } from 'mobx-state-tree'
import {
  GuildScheduledEventResponse,
  GuildScheduledEventUserResponse,
  LoadState,
  NetlifyFunctionEventsResponse,
  NetlifyFunctionEventUsersResponse,
} from '../../types'
import { EventModel } from './event'
import { EventUserModel } from './event-user'

const GuildModel = types
  .model('GuildModel', {
    id: types.identifier,
    icon: types.maybeNull(types.string),
    name: types.string,
    events: types.array(EventModel),
    eventUsers: types.map(EventUserModel),
  })
  .volatile(() => ({
    loadStateVolatile: LoadState.init,
    eventUsersLoadStateVolatile: {} as Record<string, LoadState>,
  }))
  .views((self) => ({
    get iconURI(): string {
      if (self.icon === null) {
        // return 'https://polybit-apps.s3.amazonaws.com/stdlib/users/discord/profile/image.png?1621007833204'
        return ''
      }

      let extension = '.png'
      if (self.icon?.includes('a_')) {
        extension = '.gif'
      }

      return `https://cdn.discordapp.com/icons/${self.id}/${self.icon}${extension}`
    },
    get hasEvents(): boolean {
      return self.events.length > 0
    },
  }))
  .actions((self) => ({
    addEvents(...events: GuildScheduledEventResponse[]): void {
      events.forEach((event) => {
        self.events.push(
          EventModel.create({
            id: event.id,
            channelId: event.channel_id,
            creatorId: event.creator_id,
            name: event.name,
            description: event.description,
            scheduledStartTime: event.scheduled_start_time,
            scheduledEndTime: event.scheduled_end_time,
            status: event.status,
            userCount: event.user_count,
            imageHash: event.image,
          })
        )
      })
    },
    addEventUsers(
      eventId: string,
      ...users: GuildScheduledEventUserResponse[]
    ): void {
      users.forEach((user) => {
        const existingUser = self.eventUsers.get(user.user.id)
        if (existingUser) {
          existingUser.resolveUpdatedSelf(user)
          return
        }

        self.eventUsers.put(
          EventUserModel.create({
            id: user.user.id,
            username: user.user.username,
            discriminator: user.user.discriminator,
            nickname: user.member?.nick ?? null,
            userAvatarHash: user.user.avatar,
            memberAvatarHash: user.member?.avatar,
            bannerHash: user.user.banner,
            accentColour: user.user.accent_color,
          })
        )
      })

      const event = self.events.find(({ id }) => id === eventId)
      if (!event) {
        throw new Error('event not found when trying to link users to it')
      }

      const userIds = users.map((user) => user.user.id)
      event.addEventUsers(userIds)
    },
  }))
  .actions((self) => ({
    loadEvents: flow(function* () {
      if ([LoadState.init, LoadState.error].includes(self.loadStateVolatile)) {
        self.loadStateVolatile = LoadState.inProgress

        try {
          const endpoint = `/.netlify/functions/events?guildid=${self.id}`
          const resp = yield fetch(endpoint)
          const json: NetlifyFunctionEventsResponse = yield resp.json()
          if (json.data === undefined) {
            console.error('api error=', json)
            return
          }

          // load the events into the guild
          self.addEvents(...json.data)

          self.loadStateVolatile = LoadState.done
        } catch (err) {
          console.log('error=', err)
          self.loadStateVolatile = LoadState.error
        }
      }
    }),
    loadEventUsers: flow(function* (eventId: string) {
      if ([LoadState.init, LoadState.error].includes(self.loadStateVolatile)) {
        self.eventUsersLoadStateVolatile[eventId] = LoadState.inProgress

        // check if the event id points to an existing event
        const event = self.events.find(({ id }) => id === eventId)
        if (event === undefined) {
          throw new Error('event with the specified id does not exist')
        }

        // check if the event already has its users loaded
        if (event.areEventUsersAlreadyLoaded) {
          self.eventUsersLoadStateVolatile[eventId] = LoadState.done
          return
        }

        try {
          const endpoint = `/.netlify/functions/event-users?guildid=${self.id}&eventid=${eventId}`
          const resp = yield fetch(endpoint)
          const json: NetlifyFunctionEventUsersResponse = yield resp.json()
          if (json.data === undefined) {
            console.error('api error=', json)
            return
          }

          // load the events into the guild
          self.addEventUsers(eventId, ...json.data)

          self.eventUsersLoadStateVolatile[eventId] = LoadState.done
        } catch (err) {
          console.log('error=', err)
          self.eventUsersLoadStateVolatile[eventId] = LoadState.error
        }
      }
    }),
  }))

export { GuildModel }
