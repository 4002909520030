import { Instance, types } from 'mobx-state-tree'
import { GuildScheduledEventUserResponse } from '../../types'

const EventUserModel = types
  .model('EventUserModel', {
    id: types.identifier,
    username: types.string,
    discriminator: types.string,
    nickname: types.maybeNull(types.string),
    userAvatarHash: types.maybe(types.string),
    memberAvatarHash: types.maybeNull(types.string),
    bannerHash: types.maybe(types.string),
    accentColour: types.maybe(types.number),
  })
  .views((self) => ({
    get avatarURI(): string {
      if (self.userAvatarHash === undefined) {
        return ''
      }

      let extension = '.png'
      if (self.userAvatarHash?.includes('a_')) {
        extension = '.gif'
      }

      return `https://cdn.discordapp.com/avatars/${self.id}/${self.userAvatarHash}${extension}`
    },
  }))
  .actions((self) => ({
    getCustomGuildAvatarURI(guildId: string) {
      if (self.memberAvatarHash === null) {
        throw new Error('user does not have custom guild avatar')
      }

      let extension = '.png'
      if (self.memberAvatarHash?.includes('a_')) {
        extension = '.gif'
      }

      return `https://cdn.discordapp.com/guilds/${guildId}/users/${self.id}/avatars/${self.memberAvatarHash}${extension}`
    },
  }))
  .actions((self) => ({
    resolveUpdatedSelf(copy: GuildScheduledEventUserResponse) {
      if (self.id !== copy.user.id) {
        throw new Error(
          `copy of self has id mismatch, copy: ${copy.user.id} !== self: ${self.id}`
        )
      }

      self.username = copy.user.username
      self.discriminator = copy.user.discriminator
      self.nickname = copy.member?.nick ?? null
      self.userAvatarHash = copy.user.avatar
      self.memberAvatarHash = copy.member?.avatar ?? null
      self.bannerHash = copy.user.banner
      self.accentColour = copy.user.accent_color
    },
  }))

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EventUserModelInstance extends Instance<typeof EventUserModel> {}

export { EventUserModel }
export type { EventUserModelInstance }
