class URLFragmentParams {
  private fragments: Record<string, string>

  private consumeHash(hash: string): Record<string, string> {
    // the hash is formatted as #key=value&key=value
    // so if we drop the first char and split on "&"
    // that will return an array of "key=value"
    // so we just need to split each element on "="
    // and we get separate keys and values...
    const firstCharDropped = hash.substring(1)
    const keyValuePairs = firstCharDropped.split('&')

    return keyValuePairs.reduce((obj, keyValuePair) => {
      const keyValueSplit = keyValuePair.split('=')
      return {
        ...obj,
        [keyValueSplit[0]]: keyValueSplit[1],
      }
    }, {})
  }

  constructor(hash: string) {
    this.fragments = this.consumeHash(hash)
  }

  has(key: string): boolean {
    return Object.keys(this.fragments).includes(key)
  }

  get(key: string): string {
    // check if the fragments has this key
    if (this.has(key)) {
      return this.fragments[key]
    }

    throw new Error(`key: ${key} does not exist.`)
  }
}

export { URLFragmentParams }
